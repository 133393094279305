export default {
    namespaced: true,
    state: {
        "states": [
            {
                "name": "Andhra Pradesh",
                "cities": [
                    {
                        "name": "Visakhapatnam",
                        "pincodes": ["530001", "530002", "530003", "530004", "530005"]
                    },
                    {
                        "name": "Vijayawada",
                        "pincodes": ["520001", "520002", "520003", "520004", "520005"]
                    },
                    {
                        "name": "Guntur",
                        "pincodes": ["522001", "522002", "522003", "522004", "522005"]
                    },
                    {
                        "name": "Tirupati",
                        "pincodes": ["517001", "517002", "517003", "517004", "517005"]
                    },
                    {
                        "name": "Kakinada",
                        "pincodes": ["533001", "533002", "533003", "533004", "533005"]
                    }
                ]
            },
            {
                "name": "Arunachal Pradesh",
                "cities": [
                    {
                        "name": "Itanagar",
                        "pincodes": ["791001", "791002", "791003", "791004", "791005"]
                    },
                    {
                        "name": "Naharlagun",
                        "pincodes": ["791110", "791111", "791112", "791113", "791114"]
                    },
                    {
                        "name": "Pasighat",
                        "pincodes": ["791102", "791103", "791104", "791105", "791106"]
                    },
                    {
                        "name": "Tezu",
                        "pincodes": ["792001", "792002", "792003", "792004", "792005"]
                    },
                    {
                        "name": "Ziro",
                        "pincodes": ["791120", "791121", "791122", "791123", "791124"]
                    }
                ]
            },
            {
                "name": "Assam",
                "cities": [
                    {
                        "name": "Guwahati",
                        "pincodes": ["781001", "781002", "781003", "781004", "781005"]
                    },
                    {
                        "name": "Dibrugarh",
                        "pincodes": ["786001", "786002", "786003", "786004", "786005"]
                    },
                    {
                        "name": "Silchar",
                        "pincodes": ["788001", "788002", "788003", "788004", "788005"]
                    },
                    {
                        "name": "Jorhat",
                        "pincodes": ["785001", "785002", "785003", "785004", "785005"]
                    },
                    {
                        "name": "Nagaon",
                        "pincodes": ["782001", "782002", "782003", "782004", "782005"]
                    }
                ]
            },
            {
                "name": "Bihar",
                "cities": [
                    {
                        "name": "Patna",
                        "pincodes": ["800001", "800002", "800003", "800004", "800005"]
                    },
                    {
                        "name": "Gaya",
                        "pincodes": ["823001", "823002", "823003", "823004", "823005"]
                    },
                    {
                        "name": "Bhagalpur",
                        "pincodes": ["812001", "812002", "812003", "812004", "812005"]
                    },
                    {
                        "name": "Muzaffarpur",
                        "pincodes": ["842001", "842002", "842003", "842004", "842005"]
                    },
                    {
                        "name": "Purnia",
                        "pincodes": ["854301", "854302", "854303", "854304", "854305"]
                    }
                ]
            },
            {
                "name": "Chhattisgarh",
                "cities": [
                    {
                        "name": "Raipur",
                        "pincodes": ["492001", "492002", "492003", "492004", "492005"]
                    },
                    {
                        "name": "Bilaspur",
                        "pincodes": ["495001", "495002", "495003", "495004", "495005"]
                    },
                    {
                        "name": "Korba",
                        "pincodes": ["495677", "495678", "495679", "495680", "495681"]
                    },
                    {
                        "name": "Durg",
                        "pincodes": ["491001", "491002", "491003", "491004", "491005"]
                    },
                    {
                        "name": "Jagdalpur",
                        "pincodes": ["494001", "494002", "494003", "494004", "494005"]
                    }
                ]
            },
            {
                "name": "Delhi",
                "cities": [
                    {
                        "name": "Central Delhi",
                        "pincodes": ["110001", "110002", "110003", "110004", "110005", "110006", "110007", "110008", "110009"]
                    },
                    {
                        "name": "East Delhi",
                        "pincodes": [
                            "110091", "110092", "110093", "110094", "110095", "110096", "110097", "110098", "110099"
                        ],
                    },
                    {
                        "name": "North Delhi",
                        "pincodes": [
                            "110001", "110002", "110003", "110004", "110005", "110006", "110007", "110008", "110009",
                            "110054", "110055"
                        ],
                    },
                    {
                        "name": "North West Delhi",
                        "pincodes": [
                            "110041", "110042", "110043", "110044", "110045", "110046", "110047", "110048", "110049",
                            "110085", "110086"
                        ],
                    },
                    {
                        "name": "South Delhi",
                        "pincodes": [
                            "110017", "110018", "110019", "110020", "110021", "110022", "110023", "110024", "110025",
                            "110070"
                        ],
                    },
                    {
                        "name": "West Delhi",
                        "pincodes": [
                            "110015", "110016", "110027", "110028", "110029", "110030", "110031", "110035", "110037",
                            "110075"
                        ]
                    }
                ]
            },
            {
                "name": "Goa",
                "cities": [
                    {
                        "name": "Panaji",
                        "pincodes": ["403001", "403002", "403003", "403004", "403005"]
                    },
                    {
                        "name": "Margao",
                        "pincodes": ["403601", "403602", "403603", "403604", "403605"]
                    },
                    {
                        "name": "Vasco da Gama",
                        "pincodes": ["403802", "403803", "403804", "403805", "403806"]
                    },
                    {
                        "name": "Mapusa",
                        "pincodes": ["403507", "403508", "403509", "403510", "403511"]
                    },
                    {
                        "name": "Ponda",
                        "pincodes": ["403401", "403402", "403403", "403404", "403405"]
                    }
                ]
            },
            {
                "name": "Gujarat",
                "cities": [
                    {
                        "name": "Ahmedabad",
                        "pincodes": ["380001", "380002", "380003", "380004", "380005"]
                    },
                    {
                        "name": "Surat",
                        "pincodes": ["395001", "395002", "395003", "395004", "395005"]
                    },
                    {
                        "name": "Vadodara",
                        "pincodes": ["390001", "390002", "390003", "390004", "390005"]
                    },
                    {
                        "name": "Rajkot",
                        "pincodes": ["360001", "360002", "360003", "360004", "360005"]
                    },
                    {
                        "name": "Gandhinagar",
                        "pincodes": ["382001", "382002", "382003", "382004", "382005"]
                    }
                ]
            },
            {
                "name": "Haryana",
                "cities": [
                    {
                        "name": "Chandigarh",
                        "pincodes": ["160001", "160002", "160003", "160004", "160005"]
                    },
                    {
                        "name": "Gurgaon",
                        "pincodes": ["122001", "122002", "122003", "122004", "122005"]
                    },
                    {
                        "name": "Faridabad",
                        "pincodes": ["121001", "121002", "121003", "121004", "121005"]
                    },
                    {
                        "name": "Ambala",
                        "pincodes": ["134001", "134002", "134003", "134004", "134005"]
                    },
                    {
                        "name": "Hisar",
                        "pincodes": ["125001", "125002", "125003", "125004", "125005"]
                    }
                ]
            },
            {
                "name": "Himachal Pradesh",
                "cities": [
                    {
                        "name": "Shimla",
                        "pincodes": ["171001", "171002", "171003", "171004", "171005"]
                    },
                    {
                        "name": "Dharamshala",
                        "pincodes": ["176215", "176216", "176217", "176218", "176219"]
                    },
                    {
                        "name": "Kullu",
                        "pincodes": ["175101", "175102", "175103", "175104", "175105"]
                    },
                    {
                        "name": "Manali",
                        "pincodes": ["175131", "175132", "175133", "175134", "175135"]
                    },
                    {
                        "name": "Solan",
                        "pincodes": ["173212", "173213", "173215", "173216", "173217"]
                    }
                ]
            },
            {
                "name": "Jammu and Kashmir",
                "cities": [
                    {
                        "name": "Srinagar",
                        "pincodes": [
                            "190001", "190002", "190003", "190004", "190005", "190006", "190007", "190008", "190009"
                        ],
                    },
                    {
                        "name": "Jammu",
                        "pincodes": [
                            "180001", "180002", "180003", "180004", "180005", "180006", "180007", "180008", "180009"
                        ],
                    },
                    {
                        "name": "Udhampur",
                        "pincodes": [
                            "182101", "182102", "182103"
                        ],
                    },
                    {
                        "name": "Rajouri",
                        "pincodes": [
                            "185131", "185132", "185133"
                        ],
                    },
                    {
                        "name": "Poonch",
                        "pincodes": [
                            "185101", "185102", "185103"
                        ],
                    },
                    {
                        "name": "Kathua",
                        "pincodes": [
                            "184101", "184102", "184103"
                        ],
                    },
                    {
                        "name": "Anantnag",
                        "pincodes": [
                            "192101", "192102", "192103"
                        ],
                    },
                    {
                        "name": "Pulwama",
                        "pincodes": [
                            "192301", "192302", "192303"
                        ],
                    },
                    {
                        "name": "Baramulla",
                        "pincodes": [
                            "193101", "193102", "193103"
                        ],
                    },
                    {
                        "name": "Kupwara",
                        "pincodes": [
                            "193222", "193223", "193224"
                        ]
                    },
                ]
            },

            {
                "name": "Jharkhand",
                "cities": [
                    {
                        "name": "Ranchi",
                        "pincodes": ["834001", "834002", "834003", "834004", "834005"]
                    },
                    {
                        "name": "Jamshedpur",
                        "pincodes": ["831001", "831002", "831003", "831004", "831005"]
                    },
                    {
                        "name": "Dhanbad",
                        "pincodes": ["826001", "826002", "826003", "826004", "826005"]
                    },
                    {
                        "name": "Hazaribagh",
                        "pincodes": ["825301", "825302", "825303", "825304", "825305"]
                    },
                    {
                        "name": "Dumka",
                        "pincodes": ["814101", "814102", "814103", "814104", "814105"]
                    }
                ]
            },
            {
                "name": "Karnataka",
                "cities": [
                    {
                        "name": "Bengaluru",
                        "pincodes": ["560001", "560002", "560003", "560004", "560005"]
                    },
                    {
                        "name": "Mysuru",
                        "pincodes": ["570001", "570002", "570003", "570004", "570005"]
                    },
                    {
                        "name": "Hubli",
                        "pincodes": ["580020", "580021", "580022", "580023", "580024"]
                    },
                    {
                        "name": "Belagavi",
                        "pincodes": ["590001", "590002", "590003", "590004", "590005"]
                    },
                    {
                        "name": "Mangalore",
                        "pincodes": ["575001", "575002", "575003", "575004", "575005"]
                    }
                ]
            },
            {
                "name": "Kerala",
                "cities": [
                    {
                        "name": "Thiruvananthapuram",
                        "pincodes": ["695001", "695002", "695003", "695004", "695005"]
                    },
                    {
                        "name": "Kochi",
                        "pincodes": ["682001", "682002", "682003", "682004", "682005"]
                    },
                    {
                        "name": "Kozhikode",
                        "pincodes": ["673001", "673002", "673003", "673004", "673005"]
                    },
                    {
                        "name": "Thrissur",
                        "pincodes": ["680001", "680002", "680003", "680004", "680005"]
                    },
                    {
                        "name": "Malappuram",
                        "pincodes": ["676001", "676002", "676003", "676004", "676005"]
                    }
                ]
            },
            {
                "name": "Madhya Pradesh",
                "cities": [
                    {
                        "name": "Bhopal",
                        "pincodes": ["462001", "462002", "462003", "462004", "462005"]
                    },
                    {
                        "name": "Indore",
                        "pincodes": ["452001", "452002", "452003", "452004", "452005"]
                    },
                    {
                        "name": "Gwalior",
                        "pincodes": ["474001", "474002", "474003", "474004", "474005"]
                    },
                    {
                        "name": "Jabalpur",
                        "pincodes": ["482001", "482002", "482003", "482004", "482005"]
                    },
                    {
                        "name": "Ujjain",
                        "pincodes": ["456001", "456002", "456003", "456004", "456005"]
                    }
                ]
            },
            {
                "name": "Maharashtra",
                "cities": [
                    {
                        "name": "Mumbai",
                        "pincodes": ["400001", "400002", "400003", "400004", "400005"]
                    },
                    {
                        "name": "Pune",
                        "pincodes": ["411001", "411002", "411003", "411004", "411005"]
                    },
                    {
                        "name": "Nagpur",
                        "pincodes": ["440001", "440002", "440003", "440004", "440005"]
                    },
                    {
                        "name": "Aurangabad",
                        "pincodes": ["431001", "431002", "431003", "431004", "431005"]
                    },
                    {
                        "name": "Nashik",
                        "pincodes": ["422001", "422002", "422003", "422004", "422005"]
                    }
                ]
            },
            {
                "name": "Manipur",
                "cities": [
                    {
                        "name": "Imphal",
                        "pincodes": ["795001", "795002", "795003", "795004", "795005"]
                    },
                    {
                        "name": "Thoubal",
                        "pincodes": ["795138", "795139", "795140", "795141", "795142"]
                    },
                    {
                        "name": "Churachandpur",
                        "pincodes": ["795128", "795129", "795130", "795131", "795132"]
                    },
                    {
                        "name": "Bishnupur",
                        "pincodes": ["795126", "795127", "795128", "795129", "795130"]
                    },
                    {
                        "name": "Jiribam",
                        "pincodes": ["795116", "795117", "795118", "795119", "795120"]
                    }
                ]
            },
            {
                "name": "Meghalaya",
                "cities": [
                    {
                        "name": "Shillong",
                        "pincodes": ["793001", "793002", "793003", "793004", "793005"]
                    },
                    {
                        "name": "Tura",
                        "pincodes": ["794001", "794002", "794003", "794004", "794005"]
                    },
                    {
                        "name": "Jowai",
                        "pincodes": ["793150", "793151", "793152", "793153", "793154"]
                    },
                    {
                        "name": "Nongpoh",
                        "pincodes": ["793102", "793103", "793104", "793105", "793106"]
                    },
                    {
                        "name": "Williamnagar",
                        "pincodes": ["794111", "794112", "794113", "794114", "794115"]
                    }
                ]
            },
            {
                "name": "Mizoram",
                "cities": [
                    {
                        "name": "Aizawl",
                        "pincodes": ["796001", "796002", "796003", "796004", "796005"]
                    },
                    {
                        "name": "Lunglei",
                        "pincodes": ["796701", "796702", "796703", "796704", "796705"]
                    },
                    {
                        "name": "Saiha",
                        "pincodes": ["796901", "796902", "796903", "796904", "796905"]
                    },
                    {
                        "name": "Champhai",
                        "pincodes": ["796321", "796322", "796323", "796324", "796325"]
                    },
                    {
                        "name": "Kolasib",
                        "pincodes": ["796081", "796082", "796083", "796084", "796085"]
                    }
                ]
            },
            {
                "name": "Nagaland",
                "cities": [
                    {
                        "name": "Kohima",
                        "pincodes": ["797001", "797002", "797003", "797004", "797005"]
                    },
                    {
                        "name": "Dimapur",
                        "pincodes": ["797112", "797113", "797114", "797115", "797116"]
                    },
                    {
                        "name": "Wokha",
                        "pincodes": ["797001", "797002", "797003", "797004", "797005"]
                    },
                    {
                        "name": "Mokokchung",
                        "pincodes": ["798601", "798602", "798603", "798604", "798605"]
                    },
                    {
                        "name": "Tuensang",
                        "pincodes": ["798612", "798613", "798614", "798615", "798616"]
                    }
                ]
            },
            {
                "name": "Odisha",
                "cities": [
                    {
                        "name": "Bhubaneswar",
                        "pincodes": ["751001", "751002", "751003", "751004", "751005"]
                    },
                    {
                        "name": "Cuttack",
                        "pincodes": ["753001", "753002", "753003", "753004", "753005"]
                    },
                    {
                        "name": "Rourkela",
                        "pincodes": ["769001", "769002", "769003", "769004", "769005"]
                    },
                    {
                        "name": "Berhampur",
                        "pincodes": ["760001", "760002", "760003", "760004", "760005"]
                    },
                    {
                        "name": "Balasore",
                        "pincodes": ["756001", "756002", "756003", "756004", "756005"]
                    }
                ]
            },
            {
                "name": "Punjab",
                "cities": [
                    {
                        "name": "Chandigarh",
                        "pincodes": ["160001", "160002", "160003", "160004", "160005"]
                    },
                    {
                        "name": "Amritsar",
                        "pincodes": ["143001", "143002", "143003", "143004", "143005"]
                    },
                    {
                        "name": "Ludhiana",
                        "pincodes": ["141001", "141002", "141003", "141004", "141005"]
                    },
                    {
                        "name": "Jalandhar",
                        "pincodes": ["144001", "144002", "144003", "144004", "144005"]
                    },
                    {
                        "name": "Patiala",
                        "pincodes": ["147001", "147002", "147003", "147004", "147005"]
                    }
                ]
            },
            {
                "name": "Rajasthan",
                "cities": [
                    {
                        "name": "Jaipur",
                        "pincodes": ["302001", "302002", "302003", "302004", "302005"]
                    },
                    {
                        "name": "Udaipur",
                        "pincodes": ["313001", "313002", "313003", "313004", "313005"]
                    },
                    {
                        "name": "Jodhpur",
                        "pincodes": ["342001", "342002", "342003", "342004", "342005"]
                    },
                    {
                        "name": "Bikaner",
                        "pincodes": ["334001", "334002", "334003", "334004", "334005"]
                    },
                    {
                        "name": "Kota",
                        "pincodes": ["324001", "324002", "324003", "324004", "324005"]
                    }
                ]
            },
            {
                "name": "Sikkim",
                "cities": [
                    {
                        "name": "Gangtok",
                        "pincodes": ["737101", "737102", "737103", "737104", "737105"]
                    },
                    {
                        "name": "Namchi",
                        "pincodes": ["737126", "737127", "737128", "737129", "737130"]
                    },
                    {
                        "name": "Mangan",
                        "pincodes": ["737116", "737117", "737118", "737119", "737120"]
                    },
                    {
                        "name": "Rangpo",
                        "pincodes": ["737132", "737133", "737134", "737135", "737136"]
                    },
                    {
                        "name": "Singtam",
                        "pincodes": ["737136", "737137", "737138", "737139", "737140"]
                    }
                ]
            },
            {
                "name": "Tamil Nadu",
                "cities": [
                    {
                        "name": "Chennai",
                        "pincodes": ["600001", "600002", "600003", "600004", "600005"]
                    },
                    {
                        "name": "Coimbatore",
                        "pincodes": ["641001", "641002", "641003", "641004", "641005"]
                    },
                    {
                        "name": "Madurai",
                        "pincodes": ["625001", "625002", "625003", "625004", "625005"]
                    },
                    {
                        "name": "Tiruchirappalli",
                        "pincodes": ["620001", "620002", "620003", "620004", "620005"]
                    },
                    {
                        "name": "Salem",
                        "pincodes": ["636001", "636002", "636003", "636004", "636005"]
                    }
                ]
            },
            {
                "name": "Telangana",
                "cities": [
                    {
                        "name": "Hyderabad",
                        "pincodes": ["500001", "500002", "500003", "500004", "500005"]
                    },
                    {
                        "name": "Warangal",
                        "pincodes": ["506001", "506002", "506003", "506004", "506005"]
                    },
                    {
                        "name": "Khammam",
                        "pincodes": ["507001", "507002", "507003", "507004", "507005"]
                    },
                    {
                        "name": "Nizamabad",
                        "pincodes": ["503001", "503002", "503003", "503004", "503005"]
                    },
                    {
                        "name": "Karimnagar",
                        "pincodes": ["505001", "505002", "505003", "505004", "505005"]
                    }
                ]
            },
            {
                "name": "Tripura",
                "cities": [
                    {
                        "name": "Agartala",
                        "pincodes": ["799001", "799002", "799003", "799004", "799005"]
                    },
                    {
                        "name": "Unakoti",
                        "pincodes": ["799272", "799273", "799274", "799275", "799276"]
                    },
                    {
                        "name": "Khowai",
                        "pincodes": ["799201", "799202", "799203", "799204", "799205"]
                    },
                    {
                        "name": "Dharmanagar",
                        "pincodes": ["799250", "799251", "799252", "799253", "799254"]
                    },
                    {
                        "name": "Kailashahar",
                        "pincodes": ["799277", "799278", "799279", "799280", "799281"]
                    }
                ]
            },
            {
                "name": "Uttar Pradesh",
                "cities": [
                    {
                        "name": "Lucknow",
                        "pincodes": ["226001", "226002", "226003", "226004", "226005"]
                    },
                    {
                        "name": "Kanpur",
                        "pincodes": ["208001", "208002", "208003", "208004", "208005"]
                    },
                    {
                        "name": "Agra",
                        "pincodes": ["282001", "282002", "282003", "282004", "282005"]
                    },
                    {
                        "name": "Varanasi",
                        "pincodes": ["221001", "221002", "221003", "221004", "221005"]
                    },
                    {
                        "name": "Meerut",
                        "pincodes": ["250001", "250002", "250003", "250004", "250005"]
                    }
                ]
            },
            {
                "name": "Uttarakhand",
                "cities": [
                    {
                        "name": "Dehradun",
                        "pincodes": ["248001", "248002", "248003", "248004", "248005"]
                    },
                    {
                        "name": "Haridwar",
                        "pincodes": ["249401", "249402", "249403", "249404", "249405"]
                    },
                    {
                        "name": "Nainital",
                        "pincodes": ["263001", "263002", "263003", "263004", "263005"]
                    },
                    {
                        "name": "Roorkee",
                        "pincodes": ["247667", "247668", "247669", "247670", "247671"]
                    },
                    {
                        "name": "Rudrapur",
                        "pincodes": ["263153", "263154", "263155", "263156", "263157"]
                    }
                ]
            },
            {
                "name": "West Bengal",
                "cities": [
                    {
                        "name": "Kolkata",
                        "pincodes": ["700001", "700002", "700003", "700004", "700005"]
                    },
                    {
                        "name": "Howrah",
                        "pincodes": ["711001", "711002", "711003", "711004", "711005"]
                    },
                    {
                        "name": "Durgapur",
                        "pincodes": ["713201", "713202", "713203", "713204", "713205"]
                    },
                    {
                        "name": "Siliguri",
                        "pincodes": ["734001", "734002", "734003", "734004", "734005"]
                    },
                    {
                        "name": "Asansol",
                        "pincodes": ["713301", "713302", "713303", "713304", "713305"]
                    }
                ]
            }
        ],
    },
    getters: {
        getStates: state => state.states,
    },
    mutations: {},
    actions: {},
}